<template>
  <div>
    <div class="register-form-wrap">
      <div class="container content bg-white mar-tb10">
        <div class="flex" style="padding: 0 40px;">
          <div style="width: 200px;border-right: 2px solid #C0C4CC;">
            <el-image
                style="width: 150px;height: 150px;margin-bottom: 20px;"
                fit="cover"
                :src="$store.state.setting.registerpageimage"
            ></el-image>
            <div class="pad-b20 fz-16">账号注册中心</div>
            <el-steps class="register-step" direction="vertical" :active="step">
              <el-step title="填写账号信息"></el-step>
              <el-step title="验证信息"></el-step>
              <el-step title="注册完成"></el-step>
            </el-steps>
          </div>
          <div class="register-from-wrap">
            <template v-if="step === 1">
              <el-form ref="informationForm" :model="registerConfiguration.informationForm"
                       label-width="100px" :rules="registerConfiguration.informationFormRules">
                <el-form-item class="custom-radio" prop="account_type">
                  <div class="flex">
                    <div
                        @click="registerConfiguration.informationForm.userType = 1"
                        :class="{active: registerConfiguration.informationForm.userType === 1}"
                        class="flex-item1 account-type-item">
                      <i class="el-icon-user"></i>
                      <span>个人账户</span>
                    </div>
                    <div
                        @click="registerConfiguration.informationForm.userType = 2"
                        :class="{active: registerConfiguration.informationForm.userType === 2}"
                        class="flex-item1 account-type-item">
                      <i class="el-icon-s-check"></i>
                      <span>企业账户</span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="注册账号" prop="account">
                  <el-input placeholder="4—20位字母、数字或下划线组合，不能为纯数字"
                            v-model="registerConfiguration.informationForm.account">
                    <div slot="append">（系统登录账号）</div>
                  </el-input>
                </el-form-item>
                <el-form-item label="账号密码" prop="password">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="registerConfiguration.informationForm.password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="rePassword">
                  <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                            v-model="registerConfiguration.informationForm.rePassword"></el-input>
                </el-form-item>
                <el-form-item label="常用邮箱" prop="email">
                  <el-input v-model="registerConfiguration.informationForm.email"></el-input>
                </el-form-item>
                <el-form-item :label="{
                    1: '个人名称',
                    2: '企业名称',
                  }[registerConfiguration.informationForm.userType]" prop="unitName">
                  <el-input v-model="registerConfiguration.informationForm.unitName"></el-input>
                </el-form-item>
                <el-form-item prop="read">
                  <el-radio-group v-model="registerConfiguration.informationForm.read">
                    <el-radio label="1">我已阅读并接受
                      <span @click.prevent="protocolConfiguration.showRegister = true">《{{
                          $store.state.setting.websiteTitle
                        }}》</span>
                      用户注册协议
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitRegisterInfo('informationForm')">下一步
                  </el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 2">
              <div style="color: #606266;padding: 6px 24px 50px;font-size: 16px;">注册账户-验证手机号</div>
              <el-form ref="validateForm" :model="captchaConfig.form" label-width="100px"
                       :rules="captchaConfig.formRules">
                <el-form-item label="手机号码" prop="phone">
                  <el-input placeholder="请填写您的手机号码" v-model="captchaConfig.form.phone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <el-input placeholder="请输入验证码" v-model="captchaConfig.form.code">
                    <el-button @click="captchaConfig.codeTimer.onSendCodeClick('validateForm')"
                               :disabled="captchaConfig.codeTimer.disabled"
                               slot="append">
                      {{ captchaConfig.codeTimer.text }}
                    </el-button>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button @click="--step">上一步</el-button>
                  <el-button type="primary" @click="submitValidateForm('validateForm')">下一步
                  </el-button>
                </el-form-item>
              </el-form>
            </template>
            <template v-else-if="step === 3">
              <div style="padding: 70px 0 30px;" class="tc register-success-container">
                <i class="el-icon-success"></i>
                <div class="color-main">
                  <div class="mar-b15">
                    <span class="mar-r5">注册成功</span>
                    <span>
                      <count-down
                          @finish="$router.push('/')" format="ss秒后自动跳转首页"
                          :time="5 * 1000"></count-down>
                    </span>
                  </div>
                  <router-link to="/">
                    <el-button type="primary">去首页</el-button>
                  </router-link>
                </div>
              </div>
            </template>
          </div>
        </div>
        <el-dialog
            title="注册协议"
            :visible.sync="protocolConfiguration.showRegister"
            class="register-dialog"
            center>
          <div class="rich-text" v-html="protocolConfiguration.register"></div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="protocolConfiguration.showRegister = false">确 定
            </el-button>
          </div>
        </el-dialog>
        <el-dialog class="captcha-dialog" center :visible.sync="captchaConfig.captcha.show">
          <div class="captcha" ref="captcha"></div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import asyncValidator from "@/resources/js/async-validator";
import {sendPhoneCode} from "@/apis/isrsms";
import {getagreement} from "@/apis";
import {register} from "@/apis";

require('@/resources/libs/jigsaw/jigsaw.min')
export default {
  name: 'Register',
  components: {
    CountDown: () => import('@CMP/common/CountDown')
  },
  data() {
    return {
      protocolConfiguration: {
        showRegister: false,
        register: "222",
      },
      registerImageUrl: '',
      step: 1,
      registerConfiguration: {
        informationForm: {
          userType: 1,
          account: null,
          password: null,
          rePassword: null,
          email: null,
          unitName: null,
          read: null,
        },
        informationFormRules: {
          userType: [
            {required: true, message: '请选择用户类型', trigger: ['change', 'blur']},
          ],
          account: [
            {required: true, message: '账户不能为空', trigger: ['change', 'blur']},
            {min: 4, max: 20, message: '4—20位字母、数字或下划线组合，不能为纯数字', trigger: ['blur', 'change']},
            {
              message: '4—20位字母、数字或下划线组合，不能为纯数字',
              trigger: ['blur', 'change'],
              validator: (rule, value, callback) => {
                let reg = /^(?!\d+$)[\dA-Za-z_]{4,20}$/;
                reg.test(value) ? callback()
                    : callback(new Error(rule.message))
              }
            },
          ],
          password: [
            {required: true, message: '请输入密码', trigger: ['change', 'blur']},
            {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: 'blur'},
          ],
          rePassword: [
            {required: true, message: '再次输入密码', trigger: ['change', 'blur']},
            {
              message: '两次输入密码不一致!', trigger: ['change', 'blur'],
              validator: (rule, value, callback) => {
                let {password} = this.registerConfiguration.informationForm;
                value === password ? callback()
                    : callback(new Error(rule.message))
              }
            },
          ],
          email: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback();
                  return;
                }
                asyncValidator.validEmail(rule, value, callback)
              }, message: '邮箱格式不正确', trigger: ['change', 'blur']
            },
          ],
          unitName: [
            {required: true, message: '请输入组织名称', trigger: ['change', 'blur']},
          ],
          read: [
            {required: true, message: '请确认您已阅读用户注册协议', trigger: ['change', 'blur']},
          ],
        },
      },
      captchaConfig: {
        form: {
          phone: null,
          code: null,
        },
        formRules: {
          phone: [
            {required: true, message: '请输入手机号', trigger: ['change', 'blur']},
            {validator: asyncValidator.validPhone, trigger: ['change', 'blur']},
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: ['change', 'blur']},
          ],
        },
        codeTimer: {
          disabled: false,
          time: 60,
          currentTime: 60,
          text: '获取验证码',
          onSendCodeClick: (formName) => {
            this.$refs[formName].validateField('phone', (err) => {
              if (!err) {
                this.captchaConfig.codeTimer.sendCode();
              }
            });
          },
          sendCode: () => {
            this.captchaConfig.captcha.initCaptcha()
            .then(() => {
              this.captchaConfig.codeTimer.doSendCode();
            })
          },
          start: () => {
            let {disabled, currentTime} = this.captchaConfig.codeTimer;
            if (disabled) {
              return;
            }
            this.captchaConfig.codeTimer.disabled = true;
            this.captchaConfig.codeTimer.timer = setInterval(() => {
              if (currentTime <= 1) {
                this.captchaConfig.codeTimer.stop();
              } else {
                --currentTime;
                this.captchaConfig.codeTimer.text = `${currentTime}秒后重试`;
              }
            }, 1000);
          },
          stop: () => {
            if (this.captchaConfig.codeTimer.timer) {
              clearInterval(this.captchaConfig.codeTimer.timer);
            }
            this.captchaConfig.codeTimer.currentTime = this.captchaConfig.codeTimer.time;
            this.captchaConfig.codeTimer.text = '重新获取验证码';
            this.captchaConfig.codeTimer.disabled = false;
          },
          doSendCode: () => {
            let {disabled} = this.captchaConfig.codeTimer;
            let {phone} = this.captchaConfig.form;
            if (disabled) {
              return;
            }
            this.captchaConfig.codeTimer.start();
            sendPhoneCode({
              phone: phone
            }).then(res => {
              this.captchaConfig.codeTimer.start();
              this.$message.success('发送验证码成功，请注意查收！');
            }).catch(err => {
              this.captchaConfig.codeTimer.stop();
            });
          },
        },
        captcha: {
          show: false,
          initCaptcha: () => {
            return new Promise(resolve => {
              if (!this.captchaConfig.captcha.show) {
                this.captchaConfig.captcha.show = true;
              }
              this.$nextTick(() => {
                this.$refs.captcha.innerHTML = '';
                window.jigsaw.init({
                  el: this.$refs.captcha,
                  onSuccess: () => {
                    this.$refs.captcha.innerHTML = '';
                    this.captchaConfig.captcha.show = false;
                    resolve();
                  },
                  onFail: () => {
                  },
                  onRefresh: () => {
                  }
                })
              })
            })
          },
        }
      },
    }
  },
  created() {
  },
  beforeMount() {
  },
  computed: {},
  mounted() {
    this.getagreement();
  },
  watch: {
    step: {
      handler() {
        [this.$refs['informationForm'], this.$refs['validateForm']].forEach(item => {
          if (item) {
            item.clearValidate();
          }
        })
      }
    },
  },
  methods: {
    getagreement(){
      let agreementname="用户注册协议";
      getagreement({agreementname}).then(res =>{
        this.protocolConfiguration.register=res.retdata;
      })
    },
    submitRegisterInfo(formName) {
      this.$refs[formName].validate().then(() => {
        this.nextStep();
      });
    },
    submitValidateForm(formName) {
      this.$refs[formName].validate().then(() => {
        let {userType, account, password, email, unitName} = this.registerConfiguration.informationForm;
        let {phone, code} = this.captchaConfig.form;
        register({
          phone: phone,
          password: password,
          companyName: unitName,
          userType: userType,
          account: account,
          email: email,
          code: code
        }).then(res => {
          this.$message.success('注册成功');
          this.nextStep();
        })
      })
    },
    nextStep() {
      if (this.step < 3) {
        this.step++
      }
    },
  }
}
</script>

<style scoped lang="scss">
.custom-radio {
  width: 700px;
  margin-bottom: 50px;

  ::v-deep {
    .el-radio {
      text-align: center;
    }

    .el-form-item__content {
      text-align: center;
      margin-left: 0 !important;

      > div {
        margin-left: -150px;
        margin-top: -10px;
      }
    }
  }
}

.content {
  padding: 30px 0;
}

.account-type-item {
  font-size: 18px;
  background-image: linear-gradient(to bottom, #f9f9f9, #eeeeee);
  padding: 10px 0;
  cursor: pointer;

  &.active {
    background-image: linear-gradient(to bottom, #7284ce, #4563d5);
    color: white !important;
  }

  i {
    margin-right: 5px;
  }
}

.register-step {
  height: 200px;
}

.register-from-wrap {
  width: 800px;

  .el-form {
    margin-left: 180px;
    width: 500px;
    margin-top: 10px;
  }

  .el-radio.is-bordered {
    border-radius: unset !important;
  }
}

.register-success-container .el-icon-success {
  font-size: 100px;
  color: $main-color;
  margin-bottom: 15px;
}

.el-radio.is-checked a {
  color: $main-color !important;
}

.register-dialog ::v-deep .el-dialog__body {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
